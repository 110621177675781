import React, { useEffect, useState } from 'react'
import { MenuOutlined } from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import { ADMIN_PREFIX_PATH, APP_NAME, APP_PREFIX_PATH } from 'configs/AppConfig'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'
import utils from 'utils'
import { LogoSVG } from '../../../assets/logos/icon'
import CustomIcon from '../../util-components/CustomIcon'
import NavPanel from '../Header/NavPanel'

const { Sider } = Layout
const { SubMenu } = Menu

const Admin = ({ location, navigation, toggleCollapsed, collapsed }) => {
  const history = useHistory()
  const defaultComponent = utils.getRouteInfo(navigation, location.pathname)
  const [scope, setScope] = useState(defaultComponent ? defaultComponent.parent : navigation[0].key)
  const [currentComponent, setCurrentComponent] = useState(
    defaultComponent || navigation[0].submenu[0] || navigation[0],
  )
  const intl = useIntl()

  useEffect(() => {
    const currentRouteInfos = utils.getRouteInfo(navigation, location.pathname)

    if (currentRouteInfos) {
      document.title = `${intl.formatMessage({
        id: currentRouteInfos.headerTitle,
      })} - ${APP_NAME}`
      if (currentRouteInfos.parent) {
        setScope(currentRouteInfos.parent)
      }
      else {
        setScope(currentRouteInfos.key)
      }
      setCurrentComponent(currentRouteInfos)
    }
  }, [location])

  const setClientIdReport = (id) => {
    localStorage.setItem('clientReport', id)
  }

  const renderMenuItems = (items, parentKey = '') => {
    return items.map((item) => {
      if (utils.checkUserAccess(item.rights) && utils.isBetaTester(item.betaTesters)) {
        if (item.submenu?.length) {
          return (
            <SubMenu
              key={parentKey + item.key}
              title={
                <span style={collapsed ? { paddingLeft: 6 } : {}}>
                {item.ico}
                  {collapsed && !parentKey && (
                    <div
                      className={
                        item.title.includes('social')
                          ? 'collapsed-title socials'
                          : item.title.includes('topic')
                            ? 'collapsed-title topic'
                            : item.title.includes('crowd')
                              ? 'collapsed-title crowd'
                              : item.title.includes('similars')
                                ? 'collapsed-title similars'
                                : 'collapsed-title'
                      }
                    >
                      <FormattedMessage id={item.title}/>
                    </div>
                  )}
                  {collapsed && parentKey && (
                    <span className="subItem">
                    <FormattedMessage id={item.title}/>
                  </span>
                  )}
                  {!collapsed && (
                    <span>
                    <FormattedMessage id={item.title}/>
                  </span>
                  )}
              </span>
              }
            >
              {renderMenuItems(item.submenu, parentKey + item.key + '.')}
            </SubMenu>
          )
        }
        else {
          const isExternalLink = item.path.includes(`${ADMIN_PREFIX_PATH}/MailReport`)

          return (
            <Menu.Item key={parentKey + item.key} style={{ backgroundColor: 'transparent' }}>
              {isExternalLink ? (
                <a
                  href={item.path}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => setClientIdReport && setClientIdReport(item.sys_client)}
                  style={!collapsed ? { marginLeft: -50 } : {}}
                >
                  <FormattedMessage id={item.title}/>
                </a>
              ) : (
                <Link to={item.path}>
                  <FormattedMessage id={item.title}/>
                </Link>
              )}
            </Menu.Item>
          )
        }
      }
      return null
    })
  }

  return (
    <>
      <Sider className="sider-admin" collapsed={collapsed} width="240px">
        <div className="sider-title" style={{ padding: 15 }}>
          <span onClick={toggleCollapsed} className="burger-sidebar">
            <MenuOutlined style={{ width: 20, height: 20 }}/>
          </span>
          <Link to={`${APP_PREFIX_PATH}/dashboard`} style={collapsed ? { display: 'none' } : { display: 'block' }}>
            <CustomIcon svg={LogoSVG}/>
          </Link>
        </div>
        {scope && (
          <Menu
            selectedKeys={[currentComponent.key]}
            className="menu"
            defaultOpenKeys={[scope]}
            mode="inline"
            style={{ width: 'calc(100% + -1px)', maxHeight: '100vh', overflowY: 'auto' }}
          >
            {renderMenuItems(navigation)}
          </Menu>
        )}
        <div className="sider-profile-panel">
          <NavPanel collapsed={collapsed} history={history} location={location}/>
        </div>
      </Sider>
      <style>{`
        .menu::-webkit-scrollbar {
          display: none;
        }
        .subItem {
          color: #9e9e9e;
          transition: 0.2s;
        }
        .subItem:hover {
          color: white;
          transition: 0.2s;
        }
        .menu ul {
          background-color: transparent!important;
        }
      `}</style>
    </>
  )
}

export default Admin
