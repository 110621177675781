import {RightOutlined} from '@ant-design/icons'
import {Breadcrumb} from 'antd'
import navigationConfig from 'configs/NavigationConfig'
import {Fragment} from 'react'
import {FormattedMessage} from 'react-intl'
import {useLocation} from 'react-router-dom'

export default function AppBreadcrumb({title = '', crosspost = false, listNames, crosspostTitle}) {
  const location = useLocation()
  let currentNav = []

  function containsNumbers(str) {
    return /\d/.test(str)
  }

  navigationConfig.forEach((nav) => {
    if (nav.submenu.length) {
      let found
      if (!containsNumbers(location.pathname) && !location.pathname.includes('dashboard')) {
        found = nav.submenu.find((childNav) => location.pathname.includes(childNav.path))
      } else {
        const arr = location.pathname.split('/')
        arr.pop()
        arr.push(':id')
        const route = arr.join('/')
        found = nav.submenu.find((childNav) => childNav.path === route)
      }
      if (found) {
        currentNav = [nav, found]
      }
    } else if (nav.path === location.pathname) {
      currentNav = [nav]
    }
  })

  if (location.pathname.includes('dashboard')) {
    currentNav = [navigationConfig[0], {title: 'headernav.menu.dashboard.all.platforms'}]
  }

  const buildBreadcrumb = currentNav.map((nav, index) => (
    <Fragment key={index}>
      <Breadcrumb.Item
        className={index === currentNav.length - 1 && !listNames ? 'last-breadcrumbItem' : 'breadcrumbItem'}
      >
        <span>
          {title && index === currentNav.length - 1 ? (
            title.length > 50 ? (
              title.substring(0, 90).concat('...')
            ) : (
              title
            )
          ) : (
            <FormattedMessage id={nav.title} />
          )}
        </span>
      </Breadcrumb.Item>
      {crosspost && (
        <Breadcrumb.Item className="last-breadcrumbItem">
          <span style={{color: '#949494'}}>
            {crosspostTitle && crosspostTitle?.length > 50
              ? crosspostTitle.substring(0, 90).concat('...')
              : crosspostTitle}
          </span>
        </Breadcrumb.Item>
      )}
      {containsNumbers(location.pathname) && index === 0 && (
        <Breadcrumb.Item className={index === currentNav.length - 1 ? 'last-breadcrumbItem' : 'breadcrumbItem'}>
          <span>
            <FormattedMessage id="page.meta.title.facebook.content" />
          </span>
        </Breadcrumb.Item>
      )}
      {index === currentNav.length - 1 && listNames && listNames !== '' && (
        <Breadcrumb.Item className="last-breadcrumbItem">
          <span>{listNames && listNames?.length > 50 ? listNames.substring(0, 90).concat('...') : listNames}</span>
        </Breadcrumb.Item>
      )}
    </Fragment>
  ))

  if (buildBreadcrumb && buildBreadcrumb.length > 0) {
    return <Breadcrumb separator={<RightOutlined />}>{buildBreadcrumb}</Breadcrumb>
  } else {
    return <Breadcrumb separator={<RightOutlined />}>{title}</Breadcrumb>
  }
}
